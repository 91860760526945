import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { coinConvert, viewingKeyManager } from "@stakeordie/griptape.js";
import {
  OHM_DECIMALS,
  SOHM_DECIMALS,
  SUST_DECIMALS,
} from "../constants/decimals";
import { ohmContract } from "../contracts/ohm";
import { sohmContract } from "../contracts/sohm";
import { sustContract } from "../contracts/sust";
import { setAll } from "../helpers";

export const getBalances = createAsyncThunk("account/getBalances", async () => {
  let ohm_balance;
  let sohm_balance;
  let sust_balance;

  // Viewing keys
  const ohmCurrentKey = viewingKeyManager.get(ohmContract.at);
  if (ohmCurrentKey) {
    viewingKeyManager.set(ohmContract, "my_viewing_key");
  } else {
    viewingKeyManager.add(ohmContract, "my_viewing_key");
  }

  const sohmCurrentKey = viewingKeyManager.get(sohmContract.at);
  if (sohmCurrentKey) {
    viewingKeyManager.set(sohmContract, "my_viewing_key");
  } else {
    viewingKeyManager.add(sohmContract, "my_viewing_key");
  }

  const sustCurrentKey = viewingKeyManager.get(sustContract.at);
  if (sustCurrentKey) {
    viewingKeyManager.set(sustContract, "my_viewing_key");
  } else {
    viewingKeyManager.add(sustContract, "my_viewing_key");
  }

  // // Viewing keys
  // const ohmCurrentKey = viewingKeyManager.get(ohmContract.at);
  // console.log("@@ ohmCurrentKey:", ohmCurrentKey);
  // if (!ohmCurrentKey) {
  //   const create_viewing_key_response = await ohmContract.createViewingKey();
  //   if (create_viewing_key_response.isEmpty()) {
  //     console.error("Error creating viewing key");
  //     return;
  //   }
  //   const {
  //     create_viewing_key: { key },
  //   } = create_viewing_key_response.parse();

  //   if (viewingKeyManager.get(ohmContract.at)) {
  //     viewingKeyManager.set(ohmContract, key);
  //   } else {
  //     viewingKeyManager.add(ohmContract, key);
  //   }
  //   console.log("@@ ohmCurrentKey:", ohmCurrentKey);
  // }

  // const sohmCurrentKey = viewingKeyManager.get(sohmContract.at);
  // console.log("@@ sohmCurrentKey:", sohmCurrentKey);
  // if (!sohmCurrentKey) {
  //   const create_viewing_key_response = await sohmContract.createViewingKey();
  //   if (create_viewing_key_response.isEmpty()) {
  //     console.error("Error creating viewing key");
  //     return;
  //   }
  //   const {
  //     create_viewing_key: { key },
  //   } = create_viewing_key_response.parse();

  //   if (viewingKeyManager.get(sohmContract.at)) {
  //     viewingKeyManager.set(sohmContract, key);
  //   } else {
  //     viewingKeyManager.add(sohmContract, key);
  //   }
  //   console.log("@@ sohmCurrentKey:", sohmCurrentKey);
  // }

  // const sustCurrentKey = viewingKeyManager.get(sustContract.at);
  // console.log("@@ sustCurrentKey:", sustCurrentKey);
  // if (!sustCurrentKey) {
  //   const create_viewing_key_response = await sustContract.createViewingKey();
  //   if (create_viewing_key_response.isEmpty()) {
  //     console.error("Error creating viewing key");
  //     return;
  //   }
  //   const {
  //     create_viewing_key: { key },
  //   } = create_viewing_key_response.parse();

  //   if (viewingKeyManager.get(sustContract.at)) {
  //     viewingKeyManager.set(sustContract, key);
  //   } else {
  //     viewingKeyManager.add(sustContract, key);
  //   }
  //   console.log("@@ sustCurrentKey:", sustCurrentKey);
  // }

  // OHM
  try {
    const response = await ohmContract.getBalance();
    ohm_balance = coinConvert(response.balance.amount, OHM_DECIMALS, "human");
  } catch (e) {
    console.log(`Error getting OHM balance: ${e}`);
  }

  // SOHM
  try {
    const response = await sohmContract.getBalance();
    sohm_balance = coinConvert(response.balance.amount, SOHM_DECIMALS, "human");
  } catch (e) {
    console.log(`Error getting SOHM balance: ${e}`);
  }

  // SUST
  try {
    const response = await sustContract.getBalance();
    sust_balance = coinConvert(response.balance.amount, SUST_DECIMALS, "human");
  } catch (e) {
    console.log(`Error getting SUST balance: ${e}`);
  }

  return {
    balances: {
      ohm: ohm_balance,
      sohm: sohm_balance,
      sust: sust_balance,
    },
  };
});

const initialState = {
  loading: false,
  balances: {
    ohm: "",
    sohm: "",
    sust: "",
  },
  bonds: {},
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalances.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

// const baseInfo = (state) => state.account;

// export const getAccountState = createSelector(baseInfo, (account) => account);

export const selectAccountState = (state) => state.account;
export const selectBalances = (state) => state.account.balances;
