import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import useBonds from "src/hooks/Bonds";
import {
  Box,
  Button,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
} from "@material-ui/core";
import {
  isPendingTxn,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { ClaimBondCardData } from "./ClaimRow";

function ClaimBonds({ activeBonds }) {
  const dispatch = useDispatch();
  const { bonds } = useBonds();

  const [numberOfBonds, setNumberOfBonds] = useState(0);

  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") || // TODO: there's no redeem_all in our contracts
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const onRedeemAll = async ({ autostake }) => {
    console.log("Redeeming all bonds");

    // TODO: await dispatch(redeemAllBonds({ address, bonds, autostake}))
    console.log("TODO: Actually redeem all bonds....");

    console.log("Redeem all complete");
  };

  useEffect(() => {
    let bondCount = Object.keys(activeBonds).length;
    setNumberOfBonds(bondCount);
  }, [activeBonds]);

  return (
    <>
      {numberOfBonds > 1 && (
        <Zoom in={true}>
          <Paper className="ohm-card claim-bonds-card">
            <CardHeader title="Your Bonds (1,1)" />
            <Box>
              {!isSmallScreen && (
                <TableContainer>
                  <Table aria-label="Claimable bonds">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <>Bond</>
                        </TableCell>
                        <TableCell align="center">
                          <>Claimable</>
                        </TableCell>
                        <TableCell align="center">
                          <>Pending</>
                        </TableCell>
                        <TableCell align="right">
                          <>Fully Vested</>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(activeBonds).map((bond, i) => (
                        <ClaimBondCardData key={i} userBond={bond} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {isSmallScreen &&
                Object.entries(activeBonds).map((bond, i) => (
                  <ClaimBondCardData key={i} userBond={bond} />
                ))}

              <Box
                display="flex"
                justifyContent="center"
                className={`global-claim-buttons ${
                  isSmallScreen ? "small" : ""
                }`}
              >
                {numberOfBonds > 1 && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className="transaction-button"
                      fullWidth
                      disabled={pendingClaim()}
                      onClick={() => {
                        onRedeemAll({ autostake: false });
                      }}
                    >
                      {txnButtonTextGeneralPending(
                        pendingTransactions,
                        "redeem_all_bonds", // TODO: There's no redeem_all in our contracts
                        `Claim all`
                      )}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Paper>
        </Zoom>
      )}
    </>
  );
}

export default ClaimBonds;
