import "./TreasuryDashboard.scss";

import {
  Box,
  Container,
  Grid,
  Paper,
  useMediaQuery,
  Zoom,
} from "@material-ui/core";
import { MetricCollection, Metric } from "@olympusdao/component-library";

import {
  MarketValueGraph,
  OhmStakedGraph,
  ProtocolOwnedLiquidityGraph,
  RiskFreeValueGraph,
  RunwayAvailableGraph,
  TotalValueDepositedGraph,
} from "./components/Graph/Graph";
import {
  BackingPerOhm,
  CirculatingSupply,
  CurrentIndex,
  GohmPrice,
  MarketCap,
  OhmPrice,
} from "./components/Metric/Metric";

function Dashboard() {
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 379px)");

  // Metrics
  const sharedProps = {
    labelVariant: "h6",
    metricVariant: "h5",
  };

  return (
    <div
      id="treasury-dashboard-view"
      className={`${isSmallScreen && "smaller"} ${
        isVerySmallScreen && "very-small"
      }`}
    >
      <Container
        style={{
          paddingLeft: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
          paddingRight: isSmallScreen || isVerySmallScreen ? "0" : "3.3rem",
        }}
      >
        <Box className="hero-metrics">
          <Paper className="ohm-card">
            <MetricCollection>
              <MarketCap />
              <OhmPrice />
              <GohmPrice />
              <CirculatingSupply />
              <BackingPerOhm />
              <CurrentIndex />

              {/* <Metric label="Market Cap" metric="1,690,420" {...sharedProps} />
              <Metric label="TRV Price" metric="420.69" {...sharedProps} />
              <Metric label="wsTRV Price" metric="6942.69" {...sharedProps} />
              <Metric
                label="Circulating Supply (total)"
                metric="21,000,000 / 22,000,000"
                {...sharedProps}
              />
              <Metric label="Backing per TRV" metric="42.10" {...sharedProps} />
              <Metric
                label="Current Index"
                metric="2.10 sTRV"
                {...sharedProps}
              /> */}
            </MetricCollection>
          </Paper>
        </Box>

        <Zoom in={true}>
          <Grid container spacing={2} className="data-grid">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <TotalValueDepositedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <MarketValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <RiskFreeValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <ProtocolOwnedLiquidityGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <OhmStakedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <RunwayAvailableGraph />
              </Paper>
            </Grid>
          </Grid>
        </Zoom>
      </Container>
    </div>
  );
}
export default Dashboard;
