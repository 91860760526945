import { createContractClient } from "@stakeordie/griptape.js";
import { STAKING_ADDRESS } from "../constants/contracts";

const stakingDef = {
  messages: {
    rebase() {
      const handleMsg = {
        rebase: {},
      };
      return { handleMsg };
    },

    claim(ctx) {
      const handleMsg = {
        claim: { recipient: ctx.address },
      };
      return { handleMsg };
    },

    forfeit() {
      const handleMsg = {
        forfeit: {},
      };
      return { handleMsg };
    },

    toggle_deposit_lock() {
      const handleMsg = {
        toggle_deposit_lock: {},
      };
      return { handleMsg };
    },

    give_lock_bonus(amount) {
      const handleMsg = {
        give_lock_bonus: { amount },
      };
      return { handleMsg };
    },

    set_contract(contract_type, contract) {
      const handleMsg = {
        set_contract: { contract_type, contract },
      };
      return { handleMsg };
    },

    set_warmup_period(warmup_period) {
      const handleMsg = {
        set_warmup_period: { warmup_period },
      };
      return { handleMsg };
    },
  },

  queries: {
    contract_balance() {
      return { contract_balance: {} };
    },

    index() {
      return { index: {} };
    },

    epoch() {
      return { epoch: {} };
    },

    contract_status() {
      return { contract_status: {} };
    },
  },
};

export const stakingContract = createContractClient({
  id: "staking",
  at: STAKING_ADDRESS,
  definition: stakingDef,
});
