import { useQuery } from "react-query";

export const useProtocolMetrics = (select) => {
  return useQuery(
    "protocol_metrics",
    async () => {
      // const apiUrl = "http://localhost:4000/protocolMetrics/latest";
      const apiUrl = "https://api.triviumfi.com/protocolMetrics/latest";
      let response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Error fetching from /protocolMetrics/latest");
      }
      response = await response.json();

      const [metrics] = response.data.protocolMetrics;

      return metrics;
    },
    { select }
  );
};

export const useMarketCap = () => {
  return useProtocolMetrics((metrics) => parseFloat(metrics.marketCap));
};

export const useTotalSupply = () => {
  return useProtocolMetrics((metrics) => parseFloat(metrics.totalSupply));
};

export const useTreasuryMarketValue = () => {
  return useProtocolMetrics((metrics) =>
    parseFloat(metrics.treasuryMarketValue)
  );
};

export const useOhmCirculatingSupply = () => {
  return useProtocolMetrics((metrics) =>
    parseFloat(metrics.ohmCirculatingSupply)
  );
};

export const useCurrentIndex = () => {
  return useProtocolMetrics((metrics) => parseFloat(metrics.currentIndex));
};

export const useOhmPrice = () => {
  return useProtocolMetrics((metrics) => parseFloat(metrics.ohmPrice));
};
