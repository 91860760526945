import "./TopBar.scss";

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Box, Button, SvgIcon, Toolbar } from "@material-ui/core";

import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import ThemeSwitcher from "./ThemeSwitcher";
import Wallet from "./Wallet";

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: "10px",
    },
    justifyContent: "flex-end",
    alignItems: "flex-end",
    background: "transparent",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(981)]: {
      display: "none",
    },
  },
}));

function TopBar({ theme, toggleTheme, handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className="dapp-topbar">
        <Button
          id="hamburger"
          aria-label="open drawer"
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <SvgIcon component={MenuIcon} />
        </Button>
        <Box display="flex">
          <Wallet />
          <ThemeSwitcher theme={theme} toggleTheme={toggleTheme} />
          {/* <LocaleSwitcher
                initialLocale={i18n.locale}
                locales={locales}
                onLocaleChange={selectLocale}
                label={t`change locale`}
            /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
