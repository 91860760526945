import { createContractClient } from "@stakeordie/griptape.js";
import { BOND_DEPOSITORY_ADDRESS } from "../constants/contracts";

const bondDepositoryDef = {
  messages: {
    redeem(ctx, stake) {
      const handleMsg = {
        redeem: { recipient: ctx.address, stake },
      };
      return { handleMsg };
    },
  },

  queries: {
    contract_info() {
      return { contract_info: {} };
    },

    max_payout() {
      return { max_payout: {} };
    },

    payout_for(ctx, value) {
      return { payout_for: { block_height: ctx.height, value } };
    },

    bond_price(ctx) {
      return { bond_price: { block_height: ctx.height } };
    },

    bond_price_in_usd(ctx) {
      return { bond_price_in_usd: { block_height: ctx.height } };
    },

    debt_ratio(ctx) {
      return { debt_ratio: { block_height: ctx.height } };
    },

    standardized_debt_ratio(ctx) {
      return { standardized_debt_ratio: { block_height: ctx.height } };
    },

    current_debt(ctx) {
      return { current_debt: { block_height: ctx.height } };
    },

    debt_decay(ctx) {
      return { debt_decay: { block_height: ctx.height } };
    },

    bond_terms() {
      return { bond_terms: {} };
    },

    // QueryWithPermit

    bond_info({ permit }) {
      const query = { bond_info: {} };
      return { with_permit: { query, permit } };
    },

    percent_vested_for({ permit, height }) {
      const query = { percent_vested_for: { block_height: height } };
      return { with_permit: { query, permit } };
    },

    pending_payout_for({ permit, height }) {
      const query = { pending_payout_for: { block_height: height } };
      return { with_permit: { query, permit } };
    },
  },
};

export const bondDepositoryContract = createContractClient({
  id: "bondDepository",
  at: BOND_DEPOSITORY_ADDRESS,
  definition: bondDepositoryDef,
});
