import { useQuery } from "react-query";

export const useTreasuryMetrics = (options) => {
  return useQuery(
    "treasury_metrics",
    async () => {
      // const apiUrl = "http://localhost:4000/protocolMetrics"; // TODO: Move to a constants/config file
      const apiUrl = "https://api.triviumfi.com/protocolMetrics";
      let response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Error fetching from /protocolMetrics");
      }
      response = await response.json();

      // Transform string values to floats
      return response.data.protocolMetrics.map((metric) =>
        Object.entries(metric).reduce(
          (obj, [key, value]) => ((obj[key] = parseFloat(value)), obj),
          {}
        )
      );
    },
    options
  );
};
