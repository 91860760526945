import { useNavigate } from "react-router-dom";
import { useMediaQuery, Zoom } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";

import { useAppSelector } from "src/hooks";
import { allBondsMap } from "src/helpers/AllBonds";
import ClaimBonds from "./ClaimBonds";

function ChooseBond() {
  const navigate = useNavigate();
  //   const { bonds } = useBonds();

  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const isAppLoading = useAppSelector((state) => state.app.loading);
  const isAccountLoading = useAppSelector((state) => state.account.loading); // TODO: Griptape.js way?

  const accountBonds = useAppSelector((state) => {
    const withInterestDue = [];
    for (const bond in state.account.bonds) {
      if (state.account.bonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    return withInterestDue;
  });

  const marketPrice = useAppSelector((state) => {
    return state.app.marketPrice; // TODO: use a mock price for now? Or is this reading from api response
  });

  const treasuryBalance = useAppSelector((state) => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      return tokenBalances;
    }
  });

  const formattedTreasuryBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(treasuryBalance));

  return (
    <div id="choose-bond-view">
      {!isAccountLoading && !isEmpty(accountBonds) && (
        <ClaimBonds activeBonds={accountBonds} />
      )}

      <Zoom in={true}>
        {/* TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO */}
      </Zoom>
    </div>
  );
}

export default ChooseBond;
