import { TableCell, TableRow } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import BondLogo from "src/components/BondLogo";
import { prettyVestingPeriod } from "src/helpers";
import useBonds from "src/hooks/Bonds";

export function ClaimBondCardData({ userBond }) {
  const dispatch = useDispatch();
  const { bonds, expiredBonds } = useBonds();

  const bond = userBond[1];
  const bondName = bond.bond;

  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  async function onRedeem({ autostake }) {
    let currentBond = [...bonds, ...expiredBonds].find(
      (bnd) => bnd.name === bondName
    );
    console.log("onRedeem()");
    // TODO
    //   await dispatch(redeemBond({ address, bond: currentBond, autostake}))
  }

  return (
    <TableRow id={`${bondName}--claim`}>
      <TableCell align="left" className="bond-name=cell">
        <BondLogo bond={bond} />
      </TableCell>
    </TableRow>
  );
}
