import { Link } from "@material-ui/core";
import { Icon } from "@olympusdao/component-library";

const Social = () => (
  <div className="social-row">
    <Link href="https://github.com" target="_blank">
      <Icon name="github" />
    </Link>

    <Link href="https://medium.com" target="_blank">
      <Icon name="medium" />
    </Link>

    <Link href="https://discord.com" target="_blank">
      <Icon name="discord" />
    </Link>
  </div>
);

export default Social;
