import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Fade, Grid, Modal, Typography } from "@material-ui/core";
import {
  Icon,
  Tab,
  TabPanel,
  Tabs,
  TokenStack,
} from "@olympusdao/component-library";

import { useAppSelector } from "src/hooks";
import { getAddress, onAccountAvailable } from "@stakeordie/griptape.js";
import useEscape from "src/hooks/useEscape";
import { Skeleton } from "@material-ui/lab";
import { formatCurrency } from "src/helpers";
import AdvancedSettings from "./AdvancedSettings";

const Bond = ({ index }) => {
  const navigate = useNavigate(); // useHistory() in react-router-dom v5 and older

  const bond = useAppSelector((state) => state.bonding.bonds[index]);

  const [address, setAddress] = useState();

  useEffect(() => {
    const removeOnAccountAvailable = onAccountAvailable(() => {
      setAddress(getAddress());
      setRecipientAddress(getAddress());
    });
    return () => {
      removeOnAccountAvailable();
    };
  }, []);

  const [slippage, setSlippage] = useState(0.5);
  const [recipientAddress, setRecipientAddress] = useState();

  const [view, setView] = useState(0);
  const [quantity] = useState();

  const isBondLoading = useAppSelector(
    (state) => state.bonding.loading ?? true
  );

  const onRecipientAddressChange = (e) => {
    return setRecipientAddress(e.target.value);
  };

  const onSlippageChange = (e) => {
    return setSlippage(Number(e.target.value));
  };

  const onClickAway = () => {
    navigate.push("/bonds");
  };

  useEscape(() => {
    // TODO: Why ohm frontend use `handleAdvClose` not `handleAdvClose()`?
    if (advOpen) handleAdvClose();
    else navigate.push("/bonds");
  });

  const changeView = (event, value) => {
    setView(Number(value));
  };

  // TODO: Rewrite for Griptape.js
  //   useEffect(() => {
  //       if (address) setRecipientAddress(address)
  //   }, [address])

  const [advOpen, setAdvOpen] = useState(false);
  const handleAdvOpen = () => setAdvOpen(true);
  const handleAdvClose = () => setAdvOpen(false);

  const advSettings = (
    <>
      <Icon
        name="settings"
        style={{ cursor: "pointer" }}
        onClick={handleAdvOpen}
      />
      <AdvancedSettings
        open={advOpen}
        handleClose={handleAdvClose}
        slippage={slippage}
        recipientAddress={recipientAddress}
        onRecipientAddressChange={onRecipientAddressChange}
        onSlippageChange={onSlippageChange}
      />
    </>
  );

  const headerContent = (
    <Box display="flex" flexDirection="row">
      <TokenStack tokens={bond.bondIconSvg} />
      <Box
        display="flex"
        flexDirection="column"
        ml={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5">{`${bond.displayName}`}</Typography>
      </Box>
    </Box>
  );

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Grid container>
        <Modal
          minHeight="auto"
          id="bond-view"
          open={true}
          onClose={onClickAway}
          closePosition="left"
          headerContent={headerContent}
          topRight={advSettings}
        >
          <>
            <Box
              display="flex"
              flexDirection="row"
              className="bond-price-data-row"
            >
              <div className="bond-price-data">
                <Typography variant="h5" color="textSecondary">
                  <>Bond Price</>
                </Typography>
                <Typography variant="h3" className="price" color="primary">
                  <>
                    {isBondLoading ? (
                      <Skeleton width="50px" />
                    ) : (
                      <DisplayBondPrice key={bond.name} bond={bond} />
                    )}
                  </>
                </Typography>
              </div>

              <div className="bond-price-data">
                <Typography variant="h5" color="textSecondary">
                  <>Market Price</>
                </Typography>
                <Typography variant="h3" color="primary" className="price">
                  {isBondLoading ? (
                    <Skeleton />
                  ) : (
                    formatCurrency(bond.marketPrice, 2)
                  )}
                </Typography>
              </div>
            </Box>

            <Tabs
              centered
              value={view}
              textColor="primary"
              indicatorColor="primary"
              onchange={changeView}
              aria-label="bond tabs"
            >
              <Tab
                aria-label="bond-tab-button"
                label={{
                  id: "do bond",
                  comment: "The action of bonding (verb)",
                }}
              />

              <Tab aria-label="redeem-tab-button" label={`Redeem`} />
            </Tabs>

            <TabPanel value={view} index={0}>
              {/* <BondPurchase
                bond={bond}
                slippage={slippage}
                recipientAddress={recipientAddress}
              /> */}
            </TabPanel>

            <TabPanel value={view} index={1}>
              {/* <BondRedeem bond={bond} /> */}
            </TabPanel>
          </>
        </Modal>
      </Grid>
    </Fade>
  );
};

export const DisplayBondPrice = ({ bond }) => {
  if (typeof bond.bondPrice === undefined || !bond.getBondability()) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(bond.bondPrice)}
    </Fragment>
  );
};

// export const DisplayBondDiscount =  ...

export default Bond;
