const { createSlice, createSelector } = require("@reduxjs/toolkit");

// export const calcBondDetails = ...

const setBondState = (state, payload) => {
  const bond = payload.bond;
  const newState = { ...state[bond], ...payload };
  state[bond] = newState;
  state.loading = false;
};

const initialState = {
  status: "idle",
};

const bondingSlice = createSlice({
  name: "bonding",
  initialState,
  reducers: {
    fetchBondSuccess(state, action) {
      state[action.payload.bond] = action.payload;
    },
  },

  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(calcBondDetails.pending, (state) => {
  //         state.loading = true;
  //       })
  //       .addCase(calcBondDetails.fulfilled, (state, action) => {
  //         setBondState(state, action.payload);
  //         state.loading = false;
  //       })
  //       .addCase(calcBondDetails.rejected, (state, { error }) => {
  //         state.loading = false;
  //         console.error(error.message);
  //       });
  //   },
});

export default bondingSlice.reducer;

export const { fetchBondSuccess } = bondingSlice.actions;

const baseInfo = (state) => state.bonding;

export const getBondingState = createSelector(baseInfo, (bonding) => bonding);
