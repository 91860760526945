import { useEffect, useState } from "react";

import {
  bootstrap,
  shutdown,
  onAccountAvailable,
  onAccountNotAvailable,
  onAccountChange,
  getAddress,
} from "@stakeordie/griptape.js";

import {
  Button,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  useTheme,
} from "@material-ui/core";

import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet.svg";

const WalletButton = ({ openWallet }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState();

  useEffect(() => {
    const removeOnAccountAvailable = onAccountAvailable(() => {
      setIsConnected(true);
      setAddress(getAddress());
    });
    const removeOnAccountNotAvailable = onAccountNotAvailable(() => {
      setIsConnected(false);
      setAddress(null);
    });
    const removeOnAccountChange = onAccountChange(() => {
      console.log("Account changed!");
      setIsConnected(true);
      setAddress(getAddress());
    });

    return () => {
      removeOnAccountAvailable();
      removeOnAccountNotAvailable();
      removeOnAccountChange();
    };
  }, []);

  const onClick = isConnected ? openWallet : bootstrap;
  const label = isConnected ? `${address}` : `Connect Wallet`;
  const theme = useTheme();

  return (
    <Button
      id="ohm-menu-btn"
      variant="contained"
      color="secondary"
      onClick={onClick}
    >
      <SvgIcon
        component={WalletIcon}
        style={{ marginRight: theme.spacing(1) }}
      />
      <Typography>{label}</Typography>
    </Button>
  );
};

export function Wallet() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isWalletOpen, setWalletOpen] = useState(false);

  const closeWallet = () => setWalletOpen(false);
  const openWallet = (e) => {
    setAnchorEl(e.currentTarget);
    setWalletOpen(true);
  };

  const disconnectWallet = () => {
    setWalletOpen(false);
    shutdown();
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  // only enable backdrop transition on ios devices,
  // because we can assume IOS is hosted on hight-end devices and will not drop frames
  // also disable discovery on IOS, because of it's 'swipe to go back' feat
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <WalletButton openWallet={openWallet} />
      <Menu
        open={isWalletOpen}
        onClose={closeWallet}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuItem onClick={disconnectWallet}>Disconnect wallet</MenuItem>
      </Menu>
    </>
  );
}

export default Wallet;
