import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { enablePermit } from "@stakeordie/griptape.js";
import { bondDepositoryContract } from "../contracts/bondDepository";
import { ohmContract } from "../contracts/ohm";
import { sohmContract } from "../contracts/sohm";
import { stakingContract } from "../contracts/staking";
import { treasuryContract } from "../contracts/treasury";
import { setAll } from "../helpers";

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async () => {
    let response;

    // Ohm
    response = await ohmContract.getTokenInfo();
    const ohmTotalSupply = response.token_info.total_supply;

    // Sohm
    response = await sohmContract.circulating_supply();
    const sohmCirculatingSupply =
      response.circulating_supply.circulating_supply;

    response = await sohmContract.token_constants();
    const sohmTokenConstants = response.token_constants;

    // Staking
    response = await stakingContract.index();
    const currentIndex = response.index.index;

    response = await stakingContract.contract_balance();
    const stakingContractBalance = response.contract_balance.amount;

    const epoch = await stakingContract.epoch();

    response = await stakingContract.contract_status();
    const stakingContractStatus = response.contract_status.status;

    // Treasury
    response = await treasuryContract.contract_info();
    const treasuryContractInfo = response.contract_info;

    response = await treasuryContract.contract_status();
    const treasuryContractStatus = response.contract_status.status;

    // Bond Depository
    response = await bondDepositoryContract.contract_info();
    const bondDepositoryContractInfo = response.contract_info;

    response = await bondDepositoryContract.max_payout();
    const maxPayout = response.max_payout;

    response = await bondDepositoryContract.bond_price();
    const bondPrice = response.bond_price.price;

    response = await bondDepositoryContract.bond_price_in_usd();
    const bondPriceInUsd = response.bond_price_in_usd.price;

    response = await bondDepositoryContract.debt_ratio();
    const debtRatio = response.debt_ratio.ratio;

    response = await bondDepositoryContract.standardized_debt_ratio();
    const standardizedDebtRatio = response.standardized_debt_ratio.ratio;

    response = await bondDepositoryContract.debt_decay();
    const debtDecay = response.debt_decay.decay;

    response = await ohmContract.getBalance({
      address: bondDepositoryContract.at,
      key: "ALL_ORGANISATION_INFO_SHOULD_BE_PUBLIC",
    });
    const bondDepositoryOhmBalance = response.balance.amount;

    // Bond (permit queries)
    const createPermit = async () => {
      try {
        console.log("Creating permit....");
        await enablePermit(bondDepositoryContract, ["balance"]);
        // setIsPermit(hasPermit(bondDepositoryContract));
      } catch (e) {
        console.error("Error enabling permit");
      }
    };

    await createPermit();

    const [bondInfoResponse, percentVestedForResponse] = await Promise.all([
      bondDepositoryContract.bond_info(),
      bondDepositoryContract.percent_vested_for(),
    ]);
    const bondInfo = bondInfoResponse;
    const percentVestedFor =
      Number(percentVestedForResponse.percent_vested_for.percent) / 100;

    // response = await bondDepositoryContract.bond_info();
    // const bondInfo = response;

    // response = await bondDepositoryContract.percent_vested_for();
    // const percentVestedFor =
    //   parseInt(response.percent_vested_for.percent) / 10000;

    // Calculating staking
    const REBASES_PER_DAY = 3;
    // const REBASES_PER_DAY = 60 * 24;
    const stakingReward = epoch.distribute;
    const stakingRebase =
      Number(stakingReward.toString()) /
      Number(sohmCirculatingSupply.toString());
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * REBASES_PER_DAY) - 1;
    const stakingAPY = Math.pow(1 + stakingRebase, 365 * REBASES_PER_DAY) - 1;
    console.log("5dayrate:", fiveDayRate * 100);
    console.log("stakingAPY:", stakingAPY * 100);

    const percentStaked =
      Number(stakingContractBalance) / Number(ohmTotalSupply);
    console.log("stakingContractBalance:", stakingContractBalance);
    console.log("ohmTotalSupply:", ohmTotalSupply);

    console.log({
      sohm: {
        circulatingSupply: sohmCirculatingSupply,
        totalSupply: sohmTokenConstants.total_supply,
        gonsPerFragment: sohmTokenConstants.gons_per_fragment,
        stakingContract: sohmTokenConstants.staking_contract,
      },
      bondDepository: {
        contractInfo: bondDepositoryContractInfo,
        bondPrice,
        bondPriceInUsd,
        debtRatio,
        standardizedDebtRatio,
        debtDecay,
        maxPayout,
        bondInfo,
        percentVestedFor,
        ohmBalance: bondDepositoryOhmBalance,
      },
      staking: {
        contractBalance: stakingContractBalance,
        epoch,
        contractStatus: stakingContractStatus,
        currentIndex,
      },
      treasury: {
        contractInfo: treasuryContractInfo,
        contractStatus: treasuryContractStatus,
      },
      fiveDayRate,
      stakingAPY,
      percentStaked,
    });

    return {
      sohm: {
        circulatingSupply: sohmCirculatingSupply,
        totalSupply: sohmTokenConstants.total_supply,
        gonsPerFragment: sohmTokenConstants.gons_per_fragment,
        stakingContract: sohmTokenConstants.staking_contract.address,
      },
      bondDepository: {
        contractInfo: bondDepositoryContractInfo,
        bondPrice,
        bondPriceInUsd,
        debtRatio,
        standardizedDebtRatio,
        debtDecay,
        maxPayout,
        bondInfo,
        percentVestedFor,
        ohmBalance: bondDepositoryOhmBalance,
      },
      staking: {
        contractBalance: stakingContractBalance,
        epoch,
        contractStatus: stakingContractStatus,
        currentIndex,
      },
      treasury: {
        contractInfo: treasuryContractInfo,
        contractStatus: treasuryContractStatus,
      },
      fiveDayRate,
      stakingAPY,
      percentStaked,
      // stakingTVL: ??,
      // stakingRebase: ??,
      // marketCap: ??,
      // circulatingSupply: ??,
      // totalSupply: ??,
      // treasuryMarketValue: ??,
      // secondsToEpoch: ??,
    };
  }
);

const initialState = {
  loading: false,
  sohm: {},
  bondDepository: { terms: {} },
  staking: {},
  treasury: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      console.log("fetchAppSuccess");
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAppDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

// const baseInfo = (state) => state.app;

// export const getAppState = createSelector(baseInfo, (app) => app);

export const selectAppState = (state) => state.app;
