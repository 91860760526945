import { BOND_DEPOSITORY_ADDRESS } from "src/constants/contracts";

// BondType enum
const StableAsset = "stableasset";
const LP = "lp";

export class Bond {
  constructor(type, bondOpts) {
    this.name = bondOpts.name;
    this.displayName = bondOpts.displayName;
    this.isBondable = bondOpts.isBondable;
    this.type = type;
    this.isClaimable = bondOpts.isClaimable;
    this.networkAddr = bondOpts.networkAddr;
    this.bondToken = bondOpts.bondToken;
    this.payoutToken = bondOpts.payoutToken;
  }

  getBondability() {
    return this.isBondable;
  }

  getClaimability() {
    return this.getClaimable;
  }

  getAddressForBond() {
    return this.networkAddr;
  }

  getContractForBond() {
    // TODO
    return "getContractForBond";
  }

  getAddressForReserve() {
    // TODO
    return "getAddressForReserve";
  }

  getContractForReserve() {
    // TODO
    return "getContractForReserve";
  }

  async getBondReservePrice() {
    // TODO
    return "getBondReservePrice";
  }
}

export class StableBond extends Bond {
  constructor(stableBondOpts) {
    super(StableAsset, stableBondOpts);
    this.isLP = false;
    this.displayUnits = stableBondOpts.displayName;
  }

  async getTreasuryBalance() {
    console.log("getTreasuryBalance");
  }
}

export const sust = new StableBond({
  name: "SUST",
  displayName: "SUST",
  bondToken: "SUST",
  payoutToken: "OHM",
  isBondable: true,
  isClaimable: true,
  networkAddr: BOND_DEPOSITORY_ADDRESS, // TODO: Use the correct address for this bond
});

export const allBonds = [
  // susd,
  sust,
];

export const allExpiredBonds = [];

export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
console.log(allBondsMap);

export default allBonds;
