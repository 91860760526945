import { createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ohmContract } from "../contracts/ohm";
import { sohmContract } from "../contracts/sohm";
import { stakingContract } from "../contracts/staking";
import { getBalances } from "./AccountSlice";

const dispatch = useDispatch;

export const stake = createAsyncThunk("staking/stake", async (amount) => {
  console.log(`Staking ${amount} OHM...`);
  const stake_msg = Buffer.from(JSON.stringify({ stake: {} })).toString(
    "base64"
  );

  try {
    const tx = await ohmContract.send(stakingContract.at, amount, stake_msg);
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  await dispatch(getBalances());

  return {};
});

export const unstake = createAsyncThunk("staking/unstake", async (amount) => {
  console.log(`Unstaking ${amount} SOHM...`);
  const unstake_msg = Buffer.from(
    JSON.stringify({ unstake: { trigger: false } })
  ).toString("base64");

  try {
    const tx = await sohmContract.send(stakingContract.at, amount, unstake_msg);
    console.log(tx);
  } catch (e) {
    console.error(e);
    console.log("unstake errored");
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  console.log("unstake ended");

  // Refresh account balances
  await dispatch(getBalances());

  return {};
});

export const claim = createAsyncThunk("staking/claim", async (amount) => {
  console.log(`Claiming...`);

  try {
    const tx = await stakingContract.claim();
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  dispatch(getBalances());

  return {};
});

export const rebase = createAsyncThunk("staking/rebase", async (amount) => {
  console.log(`Rebasing...`);

  try {
    const tx = await stakingContract.rebase();
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  dispatch(getBalances());

  return {};
});

export const forfeit = createAsyncThunk("staking/forfeit", async (amount) => {
  console.log(`Forfeiting...`);

  try {
    const tx = await stakingContract.forfeit();
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  dispatch(getBalances());

  return {};
});
