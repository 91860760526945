import { createAsyncThunk } from "@reduxjs/toolkit";
import { coinConvert } from "@stakeordie/griptape.js";
import { useDispatch } from "react-redux";
import { SUST_DECIMALS } from "../constants/decimals";
import { bondDepositoryContract } from "../contracts/bondDepository";
import { sustContract } from "../contracts/sust";
import { getBalances } from "./AccountSlice";

const dispatch = useDispatch;

export const deposit = createAsyncThunk("bonding/deposit", async (amount) => {
  console.log(`Depositing ${amount} for a bond...`);
  const deposit_msg = Buffer.from(
    JSON.stringify({
      deposit: { max_price: coinConvert(12345, SUST_DECIMALS, "machine") },
    })
  ).toString("base64");

  try {
    const tx = await sustContract.send(
      bondDepositoryContract.at,
      amount,
      deposit_msg
    );
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  dispatch(getBalances());

  return {};
});

export const redeem = createAsyncThunk("bonding/redeem", async (amount) => {
  console.log(`Redeem bond...`);
  try {
    const tx = await bondDepositoryContract.redeem(false);
    console.log(tx);
  } catch (e) {
    console.error(e);
    //   dispatchEvent(error(e))
  } finally {
    //   dispatch(clearPendingTx())
  }

  // Refresh account balances
  dispatch(getBalances());

  return {};
});
