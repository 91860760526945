import React, { useState, useEffect, useCallback } from "react";
import {
  // bootstrap,
  hasPermit,
  onAccountAvailable,
  viewingKeyManager,
  coinConvert,
} from "@stakeordie/griptape.js";

import {
  OHM_DECIMALS,
  SOHM_DECIMALS,
  SUST_DECIMALS,
} from "../constants/decimals";
import { useDispatch } from "react-redux";
import {
  getBalances,
  selectAccountState,
  selectBalances,
} from "../slices/AccountSlice";
import { useSelector } from "react-redux";
import { loadAppDetails, selectAppState } from "../slices/AppSlice";
import { claim, forfeit, rebase, stake, unstake } from "../slices/StakingThunk";
import { deposit, redeem } from "../slices/BondingThunk";

function Master() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  const [stakingAmount, setStakingAmount] = useState("");
  const [bondingAmount, setBondingAmount] = useState("");

  // Tokens
  const ohmBalance = useSelector((state) => state.account.balances.ohm);
  const sohmBalance = useSelector((state) => state.account.balances.sohm);
  const sustBalance = useSelector((state) => state.account.balances.sust);

  // App
  const appState = useSelector(selectAppState);
  const fiveDayRate = useSelector((state) => state.app.fiveDayRate);
  const stakingAPY = useSelector((state) => state.app.stakingAPY);
  const percentStaked = useSelector((state) => state.app.percentStaked);

  // SOHM
  const sohmCirculatingSupply = useSelector(
    (state) => state.app.sohm.circulatingSupply
  );
  const sohmTotalSupply = useSelector((state) => state.app.sohm.totalSupply);
  const gonsPerFragment = useSelector(
    (state) => state.app.sohm.gonsPerFragment
  );
  const sohmStakingContract = useSelector(
    (state) => state.app.sohm.stakingContract
  );

  // Bond
  const bondDepositoryContractInfo = useSelector(
    (state) => state.app.bondDepository.contractInfo
  );

  const bondPrice = useSelector((state) => state.app.bondDepository.bondPrice);

  const bondPriceInUsd = useSelector(
    (state) => state.app.bondDepository.bondPriceInUsd
  );

  const debtRatio = useSelector((state) => state.app.bondDepository.debtRatio);

  const standardizedDebtRatio = useSelector(
    (state) => state.app.bondDepository.standardizedDebtRatio
  );

  const debtDecay = useSelector((state) => state.app.bondDepository.debtDecay);

  const bondDepositoryOhmBalance = useSelector(
    (state) => state.app.bondDepository.ohmBalance
  );

  // Bond (permit queries)

  const bondInfo = useSelector((state) => state.app.bondDepository.bondInfo);
  const percentVestedFor = useSelector(
    (state) => state.app.bondDepository.percentVestedFor
  );

  // Treasury
  const treasuryContractInfo = useSelector(
    (state) => state.app.treasury.contractInfo
  );
  const treasuryContractStatus = useSelector(
    (state) => state.app.treasury.contractStatus
  );

  // Staking
  const stakingContractBalance = useSelector(
    (state) => state.app.staking.contractBalance
  );
  const index = useSelector((state) => state.app.staking.currentIndex);
  const epoch = useSelector((state) => state.app.staking.epoch);

  useEffect(() => {
    const removeOnAccountAvailable = onAccountAvailable(() => {
      setIsConnected(true);
      dispatch(getBalances());
      dispatch(loadAppDetails());
    });

    return () => {
      removeOnAccountAvailable();
    };
  }, []);

  return (
    <>
      <h1>Balances</h1>
      <p>OHM: {ohmBalance}</p>
      <p>SOHM: {sohmBalance}</p>
      <p>sUST: {sustBalance}</p>

      <h1>App</h1>
      <p>
        SOHM Circulating Supply:{" "}
        {coinConvert(sohmCirculatingSupply, SOHM_DECIMALS, "human")}
      </p>
      <p>OHM Staked: {percentStaked * 100} %</p>
      <p>Index: {index}</p>
      <p>5-day Rate: {(fiveDayRate * 100).toFixed(2)} %</p>
      <p>Staking APY: {(stakingAPY * 100).toFixed(2)} %</p>

      <br />

      <h1>Bond Depository</h1>

      <br />

      <input
        value={bondingAmount}
        onInput={(e) => setBondingAmount(e.target.value)}
        placeholder="Token amount to bond"
      />

      <br />

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(
            deposit(coinConvert(bondingAmount, SUST_DECIMALS, "machine"))
          );
        }}
      >
        Deposit
      </button>
      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(redeem());
        }}
      >
        Redeem (without stake)
      </button>

      <br />

      <p>
        Staking:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.staking.address
          : ""}{" "}
      </p>
      <p>
        Ohm:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.ohm.address
          : ""}
      </p>
      <p>
        Principle:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.principle.address
          : ""}
      </p>
      <p>
        Treasury:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.treasury.address
          : ""}
      </p>
      <p>
        Bond Calculator:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.bond_calculator
            ? bondDepositoryContractInfo.bond_calculator.address
            : ""
          : ""}
      </p>

      <br />

      <p>
        Unredeemed OHM:{" "}
        {coinConvert(bondDepositoryOhmBalance, OHM_DECIMALS, "human")}
      </p>

      <h2>Bond Terms</h2>
      <p>
        Control Variable:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.terms.control_variable
          : ""}
      </p>
      <p>
        Fee:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.terms.fee / 10000
          : ""}{" "}
        %
      </p>
      <p>
        Max Debt:{" "}
        {bondDepositoryContractInfo
          ? coinConvert(
              bondDepositoryContractInfo.terms.max_debt,
              OHM_DECIMALS,
              "human"
            )
          : ""}
      </p>
      <p>
        Max Payout:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.terms.max_payout
          : ""}
      </p>
      <p>
        Min Price:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.terms.minimum_price
          : ""}
      </p>
      <p>
        Vesting Term:{" "}
        {bondDepositoryContractInfo
          ? bondDepositoryContractInfo.terms.vesting_term
          : ""}
      </p>
      <p>
        Total Debt:{" "}
        {bondDepositoryContractInfo
          ? coinConvert(
              bondDepositoryContractInfo.total_debt,
              OHM_DECIMALS,
              "human"
            )
          : ""}
      </p>

      <h2>Block-dependent States</h2>
      <p>Bond Price in USD cents: {bondPrice} </p>
      <p>
        Bond Price in UST: {coinConvert(bondPriceInUsd, SUST_DECIMALS, "human")}
      </p>
      <p>
        Debt Ratio: {debtRatio} ({debtRatio / 10 ** 7} %)
      </p>
      <p>
        Standardized Debt Ratio: {standardizedDebtRatio} (
        {standardizedDebtRatio / 10 ** 7} %)
      </p>
      <p>Debt Decay: {coinConvert(debtDecay, OHM_DECIMALS, "human")}</p>

      <h2>Bond Info (Uses Permit)</h2>
      <p>Last Block: {bondInfo ? bondInfo.last_block : ""}</p>
      <p>
        Payout:{" "}
        {bondInfo ? coinConvert(bondInfo.payout, OHM_DECIMALS, "human") : ""}
      </p>
      <p>
        Price Paid::{" "}
        {bondInfo
          ? coinConvert(bondInfo.price_paid, SUST_DECIMALS, "human")
          : ""}
      </p>
      <p>Blocks left to vest: {bondInfo ? bondInfo.vesting : ""}</p>
      <p>Percent vested for: {percentVestedFor} %</p>

      <br />

      <h1>SOHM</h1>
      <p>
        Circulating Supply:{" "}
        {coinConvert(sohmCirculatingSupply, SOHM_DECIMALS, "human")}
      </p>
      <p>
        Total Supply: {coinConvert(sohmTotalSupply, SOHM_DECIMALS, "human")}
      </p>
      <p>Gons per Fragment: {gonsPerFragment} </p>
      <p>Staking Contract: {sohmStakingContract}</p>

      <br />

      <h1>Treasury</h1>
      <p>Name: {treasuryContractInfo ? treasuryContractInfo.name : ""}</p>
      <p>Admin: {treasuryContractInfo ? treasuryContractInfo.admin : ""}</p>
      <p>
        Ohm:{" "}
        {treasuryContractInfo
          ? treasuryContractInfo.ohm
            ? treasuryContractInfo.ohm.address
            : ""
          : ""}
      </p>
      <p>
        Sohm:{" "}
        {treasuryContractInfo
          ? treasuryContractInfo.sohm
            ? treasuryContractInfo.sohm.address
            : ""
          : ""}
      </p>
      <p>
        Blocks needed for queue:{" "}
        {treasuryContractInfo
          ? treasuryContractInfo.blocks_needed_for_queue
          : ""}
      </p>
      <p>
        Bond Calculator:{" "}
        {treasuryContractInfo
          ? treasuryContractInfo.bond_calculator
            ? treasuryContractInfo.bond_calculator.address
            : ""
          : ""}
      </p>
      <p>
        Total Reserves:{" "}
        {treasuryContractInfo
          ? coinConvert(
              treasuryContractInfo.total_reserves,
              OHM_DECIMALS,
              "human"
            )
          : ""}
      </p>
      <p>
        Total Debt:{" "}
        {treasuryContractInfo
          ? coinConvert(treasuryContractInfo.total_debt, OHM_DECIMALS, "human")
          : ""}
      </p>
      <p>
        Excess Reserves:{" "}
        {treasuryContractInfo
          ? coinConvert(
              treasuryContractInfo.excess_reserves,
              OHM_DECIMALS,
              "human"
            )
          : ""}
      </p>
      <p>Contract Status: {treasuryContractStatus}</p>

      <h1>Staking</h1>

      <input
        value={stakingAmount}
        onInput={(e) => setStakingAmount(e.target.value)}
        placeholder="Token amount to stake/unstake"
      />

      <br />

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(stake(coinConvert(stakingAmount, OHM_DECIMALS, "machine")));
        }}
      >
        Stake
      </button>

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(claim());
        }}
      >
        Claim
      </button>

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(rebase());
        }}
      >
        Rebase
      </button>

      <br />

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(
            unstake(coinConvert(stakingAmount, SOHM_DECIMALS, "machine"))
          );
        }}
      >
        Unstake
      </button>

      <button
        disabled={!isConnected}
        onClick={() => {
          dispatch(forfeit());
        }}
      >
        Forfeit
      </button>

      <br />

      <p>
        Contract Balance:{" "}
        {coinConvert(stakingContractBalance, OHM_DECIMALS, "human")}
      </p>
      <p>Index: {index}</p>
      <p>Epoch #: {epoch ? epoch.number : ""}</p>
      <p>Epoch Length: {epoch ? epoch.length : ""}</p>
      <p>Epoch End Block #: {epoch ? epoch.end_block : ""}</p>
      <p>
        Epoch Distribute:{" "}
        {epoch ? coinConvert(epoch.distribute, OHM_DECIMALS, "human") : ""}
      </p>

      <h2>Wallet States</h2>

      <p>Is connected? {isConnected ? "Yes" : "No"}</p>
      {/* <button
        onClick={() => {
          bootstrap();
        }}
        disabled={isConnected}
      >
        Bootstrap
      </button> */}
      <p>Has Permit? {hasPermit ? "Yes" : "No"}</p>
    </>
  );
}
export default Master;
