import {
  createContractClient,
  extendContract,
  snip20Def,
} from "@stakeordie/griptape.js";
import { SOHM_ADDRESS } from "../constants/contracts";

const sohm = {
  queries: {
    token_constants() {
      return { token_constants: {} };
    },

    circulating_supply() {
      return { circulating_supply: {} };
    },

    changes_in_rebase(profit) {
      return { changes_in_rebase: { profit } };
    },

    rebase_history(page_size, page) {
      return { rebase_history: { page_size, page } };
    },

    gons_for_balance(amount) {
      return { gons_for_balance: { amount } };
    },

    balance_for_gons(gons) {
      return { balance_for_gons: { gons } };
    },
  },
};

export const sohmContract = createContractClient({
  id: "sohm",
  at: SOHM_ADDRESS,
  definition: extendContract(snip20Def, sohm),
});
