import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Modal } from "@olympusdao/component-library";
import "./BondSettings.scss";

function AdvancedSettings(props) {
  return (
    <Modal
      id="hades"
      open={props.open}
      onClose={props.handleClose}
      maxWidth="300"
      minHeight="350"
      headerText={"Settings"}
    >
      <Box className="card=content">
        <InputLabel htmlFor="slippage">
          <>Slippage</>
        </InputLabel>
        <FormControl variant="outlined" color="primary" fullWidth>
          <OutlinedInput
            id="slippage"
            value={props.slippage}
            onChange={props.onSlippageChange}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
          <div className="helper-text">
            <Typography variant="body2" color="textSecondary">
              <>
                Transaction may revert if price changes by more than slippage %
              </>
            </Typography>
          </div>
        </FormControl>
        <InputLabel htmlFor="recipient">
          <>Recipient Address</>
        </InputLabel>
        <FormControl variant="outlined" color="primary" fullWidth>
          <OutlinedInput
            id="recipient"
            value={props.recipientAddress}
            onChange={props.onRecipientAddressChange}
            type="text"
          />
          <div className="helper-text">
            <Typography variant="body2" color="textSecondary">
              <>
                Choose recipient address. By default, this is your currently
                connected address
              </>
            </Typography>
          </div>
        </FormControl>{" "}
      </Box>
    </Modal>
  );
}

export default AdvancedSettings;
