import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/AccountSlice";
import bondingReducer from "./slices/BondSlice";
import appReducer from "./slices/AppSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    app: appReducer,
    bonding: bondingReducer,
  },
});
