export const OHM_ADDRESS = "secret1aey7g99ry3qpfkke8x6gses4jc6xe9enjj7p75";
export const SOHM_ADDRESS = "secret1hz0u6efmusfcxey8rhs3fzap3am6na5hd98qns";
export const SUST_ADDRESS = "secret1rk4zwdk7tdav0mpq3szpyz74emcrvrun9k5a3v";
export const TREASURY_ADDRESS = "secret1gukglvzkz7qunvzc3xtmyqwnyw8ct0xkq2anxt";
export const BOND_DEPOSITORY_ADDRESS =
  "secret1rk569lfghe5uxfyljwsqxl72yf0cj9sqxte7mt";
export const STAKING_ADDRESS = "secret1sfxtq7jyl7sj2qtxgzpwylvvq0364tsy0rspwp";
export const STAKING_DISTRIBUTOR_ADDRESS =
  "secret1sv5uhxkpt0nvw07mnkn43fzy0y3ke7wnrsfxu7";
export const STAKING_WARMUP_ADDRESS =
  "secret1xgd0cfkavns4x2txdnnuqtfx4jvcjnrph76agc";
