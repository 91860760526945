import { Icon } from "@material-ui/core";
import { Brightness2, BrightnessHigh } from "@material-ui/icons";
import ToggleButton from "@material-ui/lab/ToggleButton";

function ThemeSwitcher({ theme, toggleTheme }) {
  return (
    <ToggleButton
      className="toggle-button"
      type="button"
      title={`Change Theme`}
      value="check"
      onClick={toggleTheme}
    >
      {theme === "dark" ? (
        <Brightness2 color={"primary"} fontSize="small" />
      ) : (
        <BrightnessHigh color={"primary"} fontSize="small" />
      )}
    </ToggleButton>
  );
}

export default ThemeSwitcher;
