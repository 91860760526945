import "./style.scss";

import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

// import BondDepository from "./screens/BondDepository";
// import Staking from "./screens/Staking";
// import Treasury from "./screens/Treasury";
import { Dashboard, Master } from "./screens";

import {
  CssBaseline,
  makeStyles,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { dark as darkTheme } from "./themes/dark.js";
import { light as lightTheme } from "./themes/light.js";
import { girth as gTheme } from "./themes/girth.js";
import TopBar from "./components/TopBar/TopBar";
import useTheme from "./hooks/useTheme";
import Sidebar from "./components/Sidebar/Sidebar";
import Bond from "./screens/Bond/Bond";
import ChooseBond from "./screens/ChooseBond/ChooseBond";
import useBonds from "./hooks/Bonds";

// 😬 Sorry for all the console logging
const DEBUG = true;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Testnet");
// 🔭 block explorer URL

// ================ Themes ================
const drawerWidth = 280;
const transitionDuration = 969;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: transitionDuration,
    }),
    height: "100%",
    overflow: "auto",
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginLeft: 0,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));

function App() {
  const [theme, toggleTheme] = useTheme();

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  // ================ Themes ================
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  // let themeMode =
  //   theme === "light" ? lightTheme : theme === "dark" ? darkTheme : gTheme;

  let themeMode;
  themeMode = theme === "light" ? lightTheme : darkTheme;
  useEffect(() => {
    console.log(`Switching ${theme} theme`);
    themeMode = theme === "light" ? lightTheme : darkTheme;
  }, [theme]);

  // ================ Bonds ================

  const { bonds, expiredBonds } = useBonds();

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <div
        className={`app ${isSmallerScreen && "tablet"} ${
          isSmallScreen && "mobile"
        } ${theme}`}
      >
        <BrowserRouter>
          <TopBar
            theme={theme}
            toggleTheme={toggleTheme}
            handleDrawerToggle={handleDrawerToggle}
          />

          <nav className={classes.drawer}>
            {/* TODO: Render NavDrawer if isSmallerScreen */}
            <Sidebar />
          </nav>

          <div
            className={`${classes.content} ${
              isSmallerScreen && classes.contentShift
            }`}
          >
            <Routes>
              <Route path="/" element={<Master />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/bonds">
                {bonds.map((bond) => (
                  <Route
                    key={bond.name}
                    path={`/bonds/${bond.name}`}
                    element={<Bond bond={bond} />}
                  />
                ))}
                <Route element={<ChooseBond />} />
              </Route>

              <Route path="/master" element={<Master />} />
              {/* <Route path="/bond_depository" element={<BondDepository />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/treasury" element={<Treasury />} /> */}
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
export default App;
