import { createContractClient } from "@stakeordie/griptape.js";
import { TREASURY_ADDRESS } from "../constants/contracts";

const treasuryDef = {
  messages: {
    queue(ctx, address, role) {
      const handleMsg = {
        queue: { address, role },
      };
      return { handleMsg };
    },

    toggle_queue(ctx, address, role) {
      const handleMsg = {
        toggle_queue: { address, role },
      };
      return { handleMsg };
    },

    change_admin(ctx, address) {
      const handleMsg = {
        change_admin: { address },
      };
      return { handleMsg };
    },

    set_contract_status(ctx, level) {
      const handleMsg = {
        set_contract_status: { level },
      };
      return { handleMsg };
    },
  },

  queries: {
    contract_info() {
      return { contract_info: {} };
    },

    contract_status() {
      return { contract_status: {} };
    },

    managing_addresses(ctx, role) {
      return { managing_addresses: { role } };
    },
  },
};

export const treasuryContract = createContractClient({
  id: "treasury",
  at: TREASURY_ADDRESS,
  definition: treasuryDef,
});
