import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import allBonds, { allExpiredBonds } from "src/helpers/AllBonds";

const initialBondArray = allBonds;
const initialExpiredArray = allExpiredBonds;

function useBonds() {
  const test = useSelector((state) => state);
  const bondLoading = useSelector((state) => !state.bonding.loading);
  const bondState = useSelector((state) => state.bonding);
  const accountBondsState = useSelector((state) => state.account.bonds);
  const [bonds, setBonds] = useState(initialBondArray);
  const [expiredBonds, setExpiredBonds] = useState(initialExpiredArray);

  useEffect(() => {
    let bondDetails = allBonds
      .flatMap((bond) => {
        if (bondState[bond.name] && bondState[bond.name].bondDiscount) {
          return Object.assign(bond, bondState[bond.name]); // Keeps the object type
        }
        return bond;
      })
      .flatMap((bond) => {
        if (accountBondsState[bond.name]) {
          return Object.assign(bond, accountBondsState[bond.name]); // Keeps the object type
        }
        return bond;
      });

    const mostProfitableBonds = bondDetails.concat().sort((a, b) => {
      if (!a.getBondability()) return 1;
      if (!b.getBondability()) return -1;
      return a["bondDiscount"] > b["bondDiscount"]
        ? -1
        : b["bondDiscount"] > a["bondDiscount"]
        ? 1
        : 0;
    });
    setBonds(mostProfitableBonds);

    let expiredDetails = allExpiredBonds
      .flatMap((bond) => {
        if (bondState[bond.name] && bondState[bond.name].bondDiscount) {
          return Object.assign(bond, bondState[bond.name]); // Keeps the object type
        }
        return bond;
      })
      .flatMap((bond) => {
        if (accountBondsState[bond.name]) {
          return Object.assign(bond, accountBondsState[bond.name]); // Keeps the object type
        }
        return bond;
      });
    setExpiredBonds(expiredDetails);
  }, [bondState, accountBondsState, bondLoading]);

  // Debug Log:
  console.log(bonds);

  return { bonds, expiredBonds };
}

export default useBonds;
