import { useEffect } from "react";

import { Box, Divider, Link, Paper, SvgIcon } from "@material-ui/core";
import { NavItem } from "@olympusdao/component-library";

import Social from "./Social";
import { ReactComponent as OlympusIcon } from "../../assets/icons/olympus-nav-header.svg";

const NavContent = ({ handleDrawerToggle }) => {
  //   const bonds = useAppSelector((state) =>
  //     state.bonding.indexes.map((index) => state.bonding.bonds[index])
  //   );

  useEffect(() => {
    const interval = setTimeout(() => {
      //   dispatchEvent(getAllBonds({ address, networkId, provider }));
      //   dispatchEvent(getUserNotes({ address, networkId, provider }));
    }, 60 * 1000);
    return () => clearTimeout(interval);
  });

  //   const sortedBonds = bonds
  //     .filter((bond) => bond.soldOut === false)
  //     .sort((a, b) => {
  //       return a.discount > b.discount ? -1 : b.discount > a.discount ? 1 : 0;
  //     });
  //   bonds.sort((a, b) => b.bondDiscount - a.bondDiscount);

  return (
    <Paper className="dapp-sidebar">
      <Box
        className="dapp-sidebar-inner"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <Link href="https://testnet-dao.pages.dev" target="_blank">
              <SvgIcon
                color="primary"
                component={OlympusIcon}
                viewBox="0 0 151 100"
                style={{ minWidth: "151px", minHeight: "98px", width: "151px" }}
              />
            </Link>
            {/* <WalletAddressEnv /> */}
          </Box>

          <div className="dapp-menu-links">
            <div className="dapp=nav" id="navbarNav">
              <>
                <NavItem
                  to="/dashboard"
                  icon={"dashboard"}
                  label={`Dashboard`}
                />
                <NavItem to="/bonds" icon={"bond"} label={`Bond`} />
                <NavItem to="/stake" icon={"stake"} label={`Stake`} />
                <NavItem to="/wrap" icon={"wrap"} label={`Wrap`} />
                <NavItem to="/exchange" icon={"zap"} label={`Exchange`} />

                <Box className="menu-divider">
                  <Divider />
                </Box>

                <Box
                  className="dapp-menu-social"
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Social />
                </Box>
              </>
            </div>
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default NavContent;
