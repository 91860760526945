import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const pendingTxnsSlice = createSlice({
  name: "pendingTransactions",
  initialState,
  reducers: {
    fetchPendingTxns(state, action) {
      state.push(action.payload);
    },
    clearPendingTxns(state, action) {
      const target = state.find((x) => x.txnHash === action.payload); // TODO: adapt this to scrt/cosmos
      if (target) {
        state.splice(state.indexOf(target), 1);
      }
    },
  },
});

export const isPendingTxn = (pendingTransactions, type) => {
  return pendingTransactions.map((x) => x.type).includes(type);
};

export const txnButtonTextGeneralPending = (
  pendingTransactions,
  type,
  defaultText
) => {
  return pendingTransactions.length >= 2 ? `Pending...` : defaultText;
};

export default pendingTxnsSlice.reducer;
