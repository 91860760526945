import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { gripApp, getKeplrAccountProvider } from "@stakeordie/griptape.js";
import { Provider } from "react-redux";
import { store } from "./store";
import { queryClient } from "./lib/react-query";
import { QueryClientProvider } from "react-query";

const restUrl = "https://api.pulsar.griptapejs.com";
const provider = getKeplrAccountProvider();
function runApp() {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

gripApp(restUrl, provider, runApp).catch((e) => {
  console.log(`Ignoring error: ${e}`);
});

// // Auto-bootstrap on page load
// onAccountNotAvailable(() => {
//   bootstrap();
// });
