import { Drawer } from "@material-ui/core";
import NavContent from "./NavContent";
import "./Sidebar.scss";

const Sidebar = () => (
  <div className="sidebar" id="sidebarContent">
    <Drawer variant="permanent" anchor="left">
      <NavContent />
    </Drawer>
  </div>
);

export default Sidebar;
