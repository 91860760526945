import { Metric } from "@olympusdao/component-library";
import { formatCurrency } from "src/helpers";
import {
  useCurrentIndex,
  useMarketCap,
  useOhmCirculatingSupply,
  useOhmPrice,
  useTotalSupply,
  useTreasuryMarketValue,
} from "../../hooks/useProtocolMetrics";

const sharedProps = {
  labelVariant: "h6",
  metricVariant: "h5",
};

export const MarketCap = () => {
  const { data: marketCap } = useMarketCap();

  const props = {
    ...sharedProps,
    label: `Market Cap`,
  };

  if (marketCap) props.metric = formatCurrency(marketCap, 0);
  else props.isLoading = true;

  return <Metric {...props} />;
};

export const OhmPrice = () => {
  const { data: ohmPrice } = useOhmPrice();

  const props = {
    ...sharedProps,
    label: `OHM Price`,
  };

  if (ohmPrice) props.metric = formatCurrency(ohmPrice, 2);
  else props.isLoading = true;

  return <Metric {...props} />;
};

export const GohmPrice = () => {
  const { data: ohmPrice } = useOhmPrice();
  const { data: currentIndex } = useCurrentIndex();

  const props = {
    ...sharedProps,
    label: `gOHM Price`,
    className: "wsoprice",
    tooltip:
      `gOHM = sOHM * index` +
      "\n\n" +
      `The price of gOHM is equal to the price of OHM multiplied by the current index`,
  };

  if (ohmPrice && currentIndex)
    props.metric = formatCurrency(ohmPrice * currentIndex, 2);
  else props.isLoading = true;

  return <Metric {...props} />;
};

export const BackingPerOhm = () => {
  const { data: circulatingSupply } = useOhmCirculatingSupply();
  const { data: treasuryValue } = useTreasuryMarketValue();

  const props = {
    ...sharedProps,
    label: `Backing per OHM`,
  };

  if (circulatingSupply && treasuryValue)
    props.metric = formatCurrency(treasuryValue / circulatingSupply, 2);
  else props.isLoading = true;

  return <Metric {...props} />;
};

export const CirculatingSupply = () => {
  const { data: totalSupply } = useTotalSupply();
  const { data: circulatingSupply } = useOhmCirculatingSupply();

  const props = {
    ...sharedProps,
    label: `Circulating Supply (total)`,
  };

  if (totalSupply && circulatingSupply)
    props.metric = `${circulatingSupply.toFixed()} / ${totalSupply.toFixed()}`;
  else props.isLoading = true;

  return <Metric {...props} />;
};

export const CurrentIndex = () => {
  const { data: currentIndex } = useCurrentIndex();

  const props = {
    ...sharedProps,
    label: `Current Index`,
    tooltip: `The current index tracks the amount of sOHM accumulated since the beginning of staking. Basically, how much sOHM one would have if they staked and held 1 OHM from launch.`,
  };

  if (currentIndex) props.metric = `${currentIndex.toFixed(2)} sOHM`;
  else props.isLoading = true;

  return <Metric {...props} />;
};
